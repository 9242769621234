var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_tax_invoice_sn") } },
    [
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form",
                    {
                      attrs: {
                        align: "left",
                        form: _vm.form,
                        "label-col": { span: 6 },
                        "wrapper-col": { span: 12 }
                      }
                    },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.companyName.label)
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.companyName.decorator,
                                expression: "formRules.companyName.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.companyName.name,
                              placeholder: _vm.$t(
                                _vm.formRules.companyName.placeholder
                              ),
                              disabled: true
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRules.taxRegistrationNumber.label
                            )
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRules.taxRegistrationNumber.decorator,
                                expression:
                                  "formRules.taxRegistrationNumber.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.taxRegistrationNumber.name,
                              placeholder: _vm.$t(
                                _vm.formRules.taxRegistrationNumber.placeholder
                              ),
                              disabled: true
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _vm.hasPrivilegeCreate
                ? _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.disableButton,
                                icon: "plus"
                              },
                              on: { click: _vm.handleAddRow }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { type: "danger", icon: "delete" },
                              on: { click: _vm.showConfirmation }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("lbl_delete_row")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("TableCustom", {
                    attrs: {
                      idtable: "table1",
                      "data-source": _vm.dataListTaxInvoiceSerialNumber,
                      columns: _vm.columnsTable,
                      scroll: { x: "calc(700px + 50%)" },
                      paginationcustom: true,
                      "default-pagination": false,
                      loading: _vm.loadingTable,
                      "on-select-change": _vm.onSelectChange,
                      "selected-row-keys": _vm.selectedRowKeys,
                      "handle-input": _vm.handleInput,
                      "handle-date-month": _vm.handleDateMonth,
                      "handle-select": _vm.handleSelectTable
                    },
                    on: {
                      "on-view": _vm.reponseViewTable,
                      "on-download": _vm.handleDownload,
                      "on-return": _vm.handleReturn
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("Pagination", {
                    attrs: {
                      total: _vm.totalData,
                      "page-size-set": _vm.limit,
                      "id-pagination": "pagination1"
                    },
                    on: {
                      "response-pagesize-change": _vm.responsePageSizeChange,
                      "response-currentpage-change":
                        _vm.responseCurrentPageChange
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12, align: "end" } },
                [
                  _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("lbl_total_found")) +
                        " : " +
                        _vm._s(_vm.totalData) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-card-grid",
        { staticStyle: { width: "100%" }, attrs: { hoverable: false } },
        [
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _vm.hasPrivilegeCreate
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.save,
                        icon: "save"
                      },
                      on: { click: _vm.save }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }