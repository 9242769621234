































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { Messages } from "@/models/enums/messages.enum";
import { settingsServices } from "@/services/settings.service";
import { ResponsePagination } from "@/models/interface/common.interface";
import moment from "moment";
import {
  changeCurrencytoNumeric,
  currencyFormat,
  formatTaxCode,
  formatTaxCodetoNumber,
  formatTaxNumber,
} from "@/validator/globalvalidator";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {ColumnTableCustom} from "@interface/util.interface";
import {DEFAULT_DATE_FORMAT} from "@constant/date.constant";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
@Component
export default class TaxInvoiceSerialNumber extends Vue {
  formatCurrencytoNumber = changeCurrencytoNumeric;
  totalData = 0 as number;
  countAddRow = 0 as number;
  form!: WrappedFormUtils;
  dataListTaxInvoiceSerialNumber = [] as any[];
  limit = 10 as number;
  page = 0 as number;
  disableButton = false as boolean;
  labelCol = { span: 6 };
  selectedRowKeys = [] as number[];
  wrapperCol = { span: 16 };
  loadingTable = false as boolean;
  checkOnBlur = 0 as number;
  indexData = 0 as number;
  loading = {
    save: false as boolean,
  };
  formRules = {
    companyName: {
      label: "lbl_company",
      name: "companyName",
      placeholder: "lbl_company_placeholder",
      decorator: [
        "companyName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    taxRegistrationNumber: {
      label: "lbl_tax_registration_number",
      name: "taxRegistrationNumber",
      placeholder: "lbl_tax_registration_number_placeholder",
      decorator: [
        "taxRegistrationNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  columnsTable = [
    {
      title: this.$t("lbl_pkp_number"),
      dataIndex: "requestLetterNumber",
      key: "requestLetterNumber",
      width: 300,
      scopedSlots: { customRender: "requestLetterNumber" },
      responsiveColInput: [
        {
          name: "requestLetterNumber",
          placeholder: this.$t("lbl_pkp_number"),
          style: "width: 100%;",
          disabled: "",
        },
        {
          name: "declarationLetterNumber",
          placeholder: this.$t("lbl_dpj_number"),
          style: "width: 100%;",
          disabled: "",
        },
        {
          name: "taxInvoiceSnFrom",
          placeholder: this.$t("lbl_invoice_sn_from"),
          style: "width: 100%;",
          disabled: "",
        },
        {
          name: "taxInvoiceSnTo",
          placeholder: this.$t("lbl_invoice_sn_to"),
          style: "width: 100%;",
          disabled: "",
        },
      ],
      responsiveColDateMonth: [
        {
          name: "declarationLetterDate",
          placeholder: this.$t("lbl_dpj_date"),
          style: "width: 100%;",
          disabled: "",
        },
      ],
    },
    {
      title: this.$t("lbl_dpj_number"),
      dataIndex: "declarationLetterNumber",
      key: "declarationLetterNumber",
      width: 300,
      scopedSlots: { customRender: "declarationLetterNumber" },
    },
    {
      title: this.$t("lbl_dpj_date"),
      dataIndex: "declarationLetterDate",
      key: "declarationLetterDate",
      width: 300,
      scopedSlots: { customRender: "declarationLetterDate" },
    },
    {
      title: this.$t("lbl_invoice_sn_from"),
      dataIndex: "taxInvoiceSnFrom",
      key: "taxInvoiceSnFrom",
      width: 300,
      scopedSlots: { customRender: "taxInvoiceSnFrom" },
    },
    {
      title: this.$t("lbl_invoice_sn_to"),
      dataIndex: "taxInvoiceSnTo",
      key: "taxInvoiceSnTo",
      width: 300,
      scopedSlots: { customRender: "taxInvoiceSnTo" },
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "total",
      key: "total",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_total_remaining"),
      dataIndex: "totalRemaining",
      key: "totalRemaining",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_expired"),
      dataIndex: "expired",
      key: "expired",
      width: 200,
      scopedSlots: { customRender: "isEnable" },
    },
    {
      title: this.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view", "download", "return"],
      width: 120,
      align: "center",
      fixed: "right",
    }
  ] as ColumnTableCustom[];

  get hasPrivilegeCreate(): boolean {
    return this.$store.getters["authStore/GET_USER_PRIVILEGES"].find(x => x.key === "tax-invoice-sn" && x.privilege.create);
  }

  @Watch("checkOnBlur")
  public blurChangeData() {
    if (this.checkOnBlur > 0) {
      try {
        const regex = /[.-\s]/g;
        let result1 = this.dataListTaxInvoiceSerialNumber[this.indexData][
            "taxInvoiceSnFrom"
            ].replace(regex, "");
        let result2 = this.dataListTaxInvoiceSerialNumber[this.indexData][
            "taxInvoiceSnTo"
            ].replace(regex, "");
        if (result1.length === 13 && result2.length === 13) {
          if (Number(result2) - Number(result1) + 1 < 0) {
            this.$notification.error({
              message: "Error",
              description: "Total cannot minus",
            });
            this.dataListTaxInvoiceSerialNumber[this.indexData]["total"] = "";
            this.dataListTaxInvoiceSerialNumber[this.indexData][
                "totalRemaining"
                ] = "";
          } else {
            this.dataListTaxInvoiceSerialNumber[this.indexData]["total"] =
                currencyFormat(Number(result2) - Number(result1) + 1);
            this.dataListTaxInvoiceSerialNumber[this.indexData][
                "totalRemaining"
                ] = this.dataListTaxInvoiceSerialNumber[this.indexData]["total"];
          }
        }
      } catch (error) {
        this.dataListTaxInvoiceSerialNumber[this.indexData]["total"] = "";
        this.dataListTaxInvoiceSerialNumber[this.indexData]["totalRemaining"] =
            "";
      }
    }
  }
  created() {
    this.form = this.$form.createForm(this, { name: "taxinvoiceserialnumber" });
  }
  mounted() {
    this.getDataTaxSerialNumber();
    setTimeout(() => {
      this.form.setFieldsValue({
        companyName: this.$store.state.authStore.authData.companyName,
        taxRegistrationNumber: formatTaxNumber(
            this.$store.state.authStore.authData.companyTaxRegisNo
        ),
      });
    }, 500);
  }
  showConfirmation() {
    if (this.selectedRowKeys.length > 0) {
      this.$confirm({
        title: "Do you want to delete these items?",
        content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
        onOk: () => {
          this.handleDeleteRow();
        },
        onCancel() {
          return;
        },
      });
    } else {
      this.$notification.error({
        message: "Error",
        description: "Select at least one row to delete",
      });
    }
  }
  handleSelectTable(value, key, col, recordOptions) {
    this.dataListTaxInvoiceSerialNumber[key] = {
      ...this.dataListTaxInvoiceSerialNumber[key],
      [col]: value,
    };
    this.dataListTaxInvoiceSerialNumber =
        this.dataListTaxInvoiceSerialNumber.slice();
  }
  save() {
    let checkTaxInvoiceCode = [] as boolean[];
    if (this.dataListTaxInvoiceSerialNumber.length > 0) {
      checkTaxInvoiceCode.push(false);
      for (const key in this.dataListTaxInvoiceSerialNumber[
      this.dataListTaxInvoiceSerialNumber.length - 1
          ]) {
        if (key != "key" && key != "total") {
          if (
              !this.dataListTaxInvoiceSerialNumber[
              this.dataListTaxInvoiceSerialNumber.length - 1
                  ][key]
          ) {
            checkTaxInvoiceCode.push(true);
          }
        }
      }
      if (checkTaxInvoiceCode.includes(true)) {
        setTimeout(() => {
          this.$notification.error({
            message: "Tax Invoice Serial Number",
            description:
                "Request Letter (PKP) Number , Declaration Letter (DPJ) Number , Declaration Letter Date , Tax Invoice Serial Number From , Tax Invoice Serial Number To , Total is Mandatory !",
          });
        }, 100);
      } else {
        const payload = {
          requestLetterNumber:
          this.dataListTaxInvoiceSerialNumber[
          this.dataListTaxInvoiceSerialNumber.length - 1
              ].requestLetterNumber,
          declarationLetterNumber:
          this.dataListTaxInvoiceSerialNumber[
          this.dataListTaxInvoiceSerialNumber.length - 1
              ].declarationLetterNumber,
          declarationLetterDate:
          this.dataListTaxInvoiceSerialNumber[
          this.dataListTaxInvoiceSerialNumber.length - 1
              ].declarationLetterDate,
          taxInvoiceSnFrom:
          this.dataListTaxInvoiceSerialNumber[
          this.dataListTaxInvoiceSerialNumber.length - 1
              ].taxInvoiceSnFrom,
          taxInvoiceSnTo:
          this.dataListTaxInvoiceSerialNumber[
          this.dataListTaxInvoiceSerialNumber.length - 1
              ].taxInvoiceSnTo,
          total: this.formatCurrencytoNumber(
              this.dataListTaxInvoiceSerialNumber[
              this.dataListTaxInvoiceSerialNumber.length - 1
                  ].total
          ),
          totalRemaining: this.formatCurrencytoNumber(
              this.dataListTaxInvoiceSerialNumber[
              this.dataListTaxInvoiceSerialNumber.length - 1
                  ].totalRemaining
          ),
        };
        this.loading.save = true;
        settingsServices
            .createTaxInvoiceSerialNumber(payload)
            .then(() => {
              this.$notification.success({
                description: Messages.CREATE_SUCCESS,
                message: "Success",
                duration: 30,
              });
              this.countAddRow = 0;
              this.getDataTaxSerialNumber();
            })
            .catch(() => (this.loading.save = false))
            .finally(() => (this.loading.save = false));
      }
    } else {
      setTimeout(() => {
        this.$notification.error({
          message: "Tax Invoice Serial Number",
          description: "Tax Invoice Serial Number at least have 1 data",
        });
      }, 100);
    }
  }
  getDataTaxSerialNumber() {
    let params = {
      limit: this.limit,
      page: this.page,
      sorts: "createdDate:desc",
    } as RequestQueryParamsModel;
    this.loadingTable = true;
    settingsServices
        .listOfTaxInvoiceSerialNumber(params)
        .then((res) => {
          res.data.forEach(
              (item, index) => (
                  (item.key = index),
                      (item.total = currencyFormat(item.total)),
                      (item.totalRemaining = currencyFormat(item.qtyRemaining)),
                      (item.disabledInput = true),
                      (item.disabledDateMonth = true)
              )
          );
          this.dataListTaxInvoiceSerialNumber = res.data;
          const totalValue = res.data[0].total;
          const checkExpiredValue = moment(
              res.data[0].declarationLetterDate
          ).diff(
              moment(res.data[0].declarationLetterDate).add(1, "years"),
              "days"
          );
          if (checkExpiredValue < 0) {
            if (this.formatCurrencytoNumber(totalValue) <= 100) {
              this.disableButton = false;
            } else {
              // this.disableButton = true;
              this.disableButton = false;
            }
          } else {
            this.disableButton = false;
          }
          this.totalData = res.totalElements;
        })
        .finally(() => (this.loadingTable = false));
  }
  handleInput(
      value,
      key,
      objectColInput,
      objectColInputName: string,
      onEvt: string
  ) {
    let formatedVal: string | number = value;
    if (
        objectColInputName == "taxInvoiceSnFrom" ||
        objectColInputName == "taxInvoiceSnTo"
    ) {
      this.indexData = key;
      const regex = /[.-\s]/g;
      let result = value.replace(regex, "");
      if (result) {
        if (onEvt === "onBlur") {
          if (result.length < 13) {
            setTimeout(() => {
              this.$notification.error({
                message: "Tax Invoice Serial Number",
                description: "Tax must 13 digit number",
              });
            }, 100);
            if (value.includes("."))
              formatedVal = formatTaxCodetoNumber(result);
            else formatedVal = value;
          } else if (result.length == 13) {
            formatedVal = formatTaxCode(result);
          } else {
            formatedVal = formatTaxCode(result.slice(0, 13));
          }
          this.checkOnBlur += 1;
        } else if (onEvt === "onFocus") {
          if (result.length == 13) {
            formatedVal = formatTaxCode(result);
          }
          this.checkOnBlur = 0;
        }
      }
    }
    this.dataListTaxInvoiceSerialNumber[key][objectColInput.name] = formatedVal;
    this.dataListTaxInvoiceSerialNumber =
        this.dataListTaxInvoiceSerialNumber.slice();
  }
  handleDateMonth(value, key, objectColDate, dateString) {
    this.dataListTaxInvoiceSerialNumber[key][objectColDate] =
        moment(value).format();
    this.dataListTaxInvoiceSerialNumber =
        this.dataListTaxInvoiceSerialNumber.slice();
  }
  onSelectChange(selectedRowKeys) {
    this.selectedRowKeys = selectedRowKeys;
  }
  handleDeleteRow() {
    let dataListDeleteNoId = [] as number[];
    let dataListDeleteWithId = [] as number[];
    this.dataListTaxInvoiceSerialNumber.forEach((item) => {
      if (this.selectedRowKeys.includes(item.key)) {
        if (!item.id) dataListDeleteNoId.push(item.key);
        else dataListDeleteWithId.push(item.id);
      }
    });
    this.dataListTaxInvoiceSerialNumber =
        this.dataListTaxInvoiceSerialNumber.filter((data) => {
          return !dataListDeleteNoId.includes(data.key);
        });
    if (dataListDeleteWithId.length > 0) {
      const params = {
        id: dataListDeleteWithId.toString(),
      } as RequestQueryParamsModel;
      settingsServices.deleteTaxInvoiceSerialNumber(params).then(() => {
        this.selectedRowKeys = [];
        this.getDataTaxSerialNumber();
      });
    } else {
      this.selectedRowKeys = [];
    }

    this.dataListTaxInvoiceSerialNumber =
        this.dataListTaxInvoiceSerialNumber.slice();
    this.totalData = this.dataListTaxInvoiceSerialNumber.length;
    this.checkOnBlur += 1;
    this.selectedRowKeys = [];
    this.countAddRow -= 1;
    if (this.countAddRow <= 1) {
      this.disableButton = false;
    }
  }
  handleAddRow() {
    this.countAddRow += 1;
    if (this.countAddRow <= 1) {
      this.dataListTaxInvoiceSerialNumber = [
        ...this.dataListTaxInvoiceSerialNumber,
        {
          requestLetterNumber: null,
          declarationLetterNumber: null,
          declarationLetterDate: null,
          taxInvoiceSnFrom: null,
          taxInvoiceSnTo: null,
          total: null,
          totalRemaining: null,
          key: this.dataListTaxInvoiceSerialNumber.length,
        },
      ];
      // this.disableButton = true;
      this.disableButton = false;
    } else {
      this.disableButton = false;
    }
    this.totalData = this.dataListTaxInvoiceSerialNumber.length;
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 0;
    this.getDataTaxSerialNumber();
  }
  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page - 1;
    this.getDataTaxSerialNumber();
  }
  reponseViewTable(response) {
    if (response.data.id)
      this.$router.push(
          "/settings/taxinvoiceserialnumber/detail/" + response.data.id
      );
    else
      setTimeout(() => {
        this.$notification.error({
          message: "Tax Invoice Serial Number",
          description: "Data Not Found",
        });
      }, 100);
  }

  handleDownload(data): void {
    this.$message.loading("Downloading the report...");
    settingsServices
        .downloadOfTaxInvoiceSerialNumber(data.data.id)
        .then((file) => {
          if (file) {
            const url = window.URL.createObjectURL(new Blob([file]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                "Unused Tax Invoice Serial Number.xlsx"
            );
            document.body.appendChild(link);
            link.click();
            this.$message.success("Report has been successfully downloaded");
            window.URL.revokeObjectURL(url);
          } else {
            this.$message.info("No data found", 30);
          }
        });
  }
  handleReturn(data) {
    this.$confirm({
      title: "Are you sure to submit this action?",
      content: `Your Tax Invoice Number List with ${
          data.data.declarationLetterNumber
      } and ${moment(data.data.declarationLetterDate).format(
          DEFAULT_DATE_FORMAT
      )} will return to Tax Office. After return, you can not use this list number to your next transactions. `,
      onOk: () => {
        if (!data.data.expired) {
          settingsServices.expiredTaxInvoiceCode(data.data.id).then(() => {
            this.getDataTaxSerialNumber();
            this.$notification.success({
              description: Messages.UPDATE_SUCCESS,
              message: "Success",
              duration: 30,
            });
          });
        } else {
          this.$notification.error({
            message: "Error",
            description: "Data has expired",
          });
        }
      },
      onCancel() {
        return;
      },
    });
  }
}
